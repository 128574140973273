import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const setJurisdictionType =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_JURISDICTION_TYPE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const setJurisdiction = firebase
        .functions()
        .httpsCallable("setJurisdiction");
      const result = await setJurisdiction(data);
      if (result.data.success) {
        dispatch({
          type: "SET_JURISDICTION_TYPE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SET_JURISDICTION_TYPE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_JURISDICTION_TYPE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getJurisdictionType =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const existingSuccess =
        getState().jurisdiction.jurisdictionsLoading.success;
      const orgId = getState().user.currentUser.orgId;
      if (!existingSuccess) {
        dispatch({
          type: "GET_JURISDICTION_TYPE_LOADING",
          payload: { loading: true, success: false, error: null },
        });
        const firestore = getFirestore();
        let jurisdictionSnapshot = firestore
          .collection("organizations")
          .doc(orgId)
          .collection("jurisdictions")
          .onSnapshot((jurisdictionSnapshot) => {
            if (!jurisdictionSnapshot.empty) {
              let jurisdictions = [];
              for (const doc of jurisdictionSnapshot.docs) {
                const data = doc.data();
                const finalDoc = {
                  ...data,
                  id: doc.id,
                };
                jurisdictions.push(finalDoc);
              }

              dispatch({
                type: "GET_JURISDICTION_TYPE",
                payload: jurisdictions,
              });
              dispatch({
                type: "GET_JURISDICTION_TYPE_LOADING",
                payload: { loading: false, success: true, error: null },
              });
            } else {
              dispatch({
                type: "GET_JURISDICTION_TYPE",
                payload: [],
              });
              dispatch({
                type: "GET_JURISDICTION_TYPE_LOADING",
                payload: {
                  loading: false,
                  success: true,
                  error: "Please add Province from settings page",
                },
              });
            }
          });
        dispatch(
          addSnapshotsListners({
            type: "jurisdiction-snapshot",
            snapshot: jurisdictionSnapshot,
          })
        );
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_JURISDICTION_TYPE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const getCurrentUserJurisdiction = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "GET_CURRENT_USER_JURISDICTION_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    try {
      const user = data ? data : getState().user.currentUser;
      const orgId = getState().user.currentUser.orgId;
      const provice = data ? data : user.homeProvince;
      const jurisdictionCollectionRef = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("jurisdictions");
      const jurisdictionQueryRef = jurisdictionCollectionRef.where(
        "provinceName",
        "==",
        provice
      );
      const jurisdctionQueryRes = await jurisdictionQueryRef.get();
      if (!jurisdctionQueryRes.empty) {
        const jurisdiction = {
          ...jurisdctionQueryRes.docs[0].data(),
          docId: jurisdctionQueryRes.docs[0].id,
        };
        dispatch({
          type: "GET_CURRENT_USER_JURISDICTION_DATA",
          payload: jurisdiction,
        });
        dispatch({
          type: "GET_CURRENT_USER_JURISDICTION_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorMessage = {
          status: 400,
          message: "User province not found",
        };
        throw errorMessage;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_CURRENT_USER_JURISDICTION_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const deleteJurisdictionType =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: "SET_DELETE_JURISDICTION_TYPE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const deleteAdminFunction = firebase
        .functions()
        .httpsCallable("deleteAdminFunction");
      const response = await deleteAdminFunction(data);
      console.log(response);
      if (response.data.success) {
        dispatch({
          type: "SET_DELETE_JURISDICTION_TYPE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SET_DELETE_JURISDICTION_TYPE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: response.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_DELETE_JURISDICTION_TYPE_LOADING",
        payload: { loading: true, success: false, error: err.message },
      });
    }
  };

export const resetJurisdictionTypeLoading = () => (dispatch) => {
  dispatch({
    type: "SET_JURISDICTION_TYPE_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetDeleteJurisdictionTypeLoading = () => (dispatch) => {
  dispatch({
    type: "SET_DELETE_JURISDICTION_TYPE_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};
