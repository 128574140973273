const initialState = {
  allEmployeesApprovedLicenses: [],
  allPendingLicenses: [],
  activeBrokerLicenses: [],
  pendingBrokerLicenses: [],
  expiredBrokerLicenses: [],
  addLicenseLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fetchAllEmployeesApprovedLicensesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fetchAllPendingLicensesLoading: {
    loading: false,
    success: false,
    errror: null,
  },
  fetchBrokerActiveLicensesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fetchBrokerPendingLicensesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  approveLicenseLoading: {
    loading: false,
    success: false,
    error: null,
  },

  terminateLicenseLoading: {
    loading: false,
    success: false,
    error: null,
  },
  expiredLicenseLoading: {
    loading: false,
    success: false,
    error: null,
  },

  deleteLicenseLoading: {
    loading: false,
    success: false,
    error: null,
  },
};

const LicensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_LICENSE_LOADING":
      return { ...state, addLicenseLoading: action.payload };
    case "GET_BROKER_LICENSE_LOADING":
      return { ...state, fetchBrokerLicensesLoading: action.payload };
    case "SET_BROKER_LICENSE":
      return { ...state, activeBrokerLicenses: action.payload };
    case "SET_ALL_EMPLOYEES_LICENSE_ADMIN":
      return { ...state, allEmployeesLicenses: action.payload };
    case "GET_BROKER_PENDING_LICENSE_LOADING":
      return { ...state, fetchBrokerPendingLicensesLoading: action.payload };
    case "SET_BROKER_PENDING_LICENSES":
      return { ...state, pendingBrokerLicenses: action.payload };
    case "GET_ALL_BROKER_PENDING_LICENSE_LOADING":
      return { ...state, fetchAllPendingLicensesLoading: action.payload };
    case "SET_ALL_BROKERS_PENDING_LICENSES":
      return { ...state, allPendingLicenses: action.payload };
    case "APPROVE_LICENSE_LOADING":
      return { ...state, approveLicenseLoading: action.payload };
    case "GET_ALL_EMPLOYEES_APPROVED_LICENSE_LOADING":
      return {
        ...state,
        fetchAllEmployeesApprovedLicensesLoading: action.payload,
      };
    case "SET_ALL_EMPLOYEES_PENDING_LICENSES":
      return { ...state, allEmployeesApprovedLicenses: action.payload };
    case "TERMINATE_LICENSE_LOADING":
      return { ...state, terminateLicenseLoading: action.payload };
    case "GET_ALL_BROKERS_EXPIRED_LICENSES":
      return { ...state, expiredLicenseLoading: action.payload };
    case "SET_ALL_BROKERS_EXPIRED_LICENSES":
      return { ...state, expiredBrokerLicenses: action.payload };
    case "DELETE_LICENSE_LOADING":
      return { ...state, deleteLicenseLoading: action.payload };
    default:
      return state;
  }
};

export default LicensesReducer;
