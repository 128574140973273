const initialState = {
  createBrokerLoading: {
    loading: false,
    error: null,
    success: false,
  },
  allBrokers: [],
  formerBrokers: [],
  brokerCETypes: [],
  deleteBrokerLoading: {
    loading: false,
    error: null,
    success: false,
  },
  getAllBrokersLoading: {
    loading: false,
    success: false,
    error: null,
  },
  getBrokersCETypesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  sendBrokerEmailLoading: {
    loading: false,
    success: false,
    error: null,
  },
  addingBrokerInBulkLoading: {
    loading: false,
    success: false,
    error: null,
  },
  automatedEmailsFeatureLoading: {
    loading: false,
    success: false,
    error: null,
  },
  changeBrokerStatusLoading: {
    loading: false,
    succes: false,
    error: null,
  },
  getAllFormerBrokersLoading: {
    loading: false,
    success: false,
    error: null,
  },
  changeBrokerRoleLoading: {
    loading: false,
    error: null,
    success: false,
  },
  singleBrokerData: {},
  resetBrokerModal: true,
  combineBrokers: [],
  percentage: { complete: 0, incomplete: 0, fail: 0 },

};

const brokerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_BROKER_LOADING":
      return { ...state, createBrokerLoading: action.payload };
    case "CREATE_BROKER_IN_BULK_LOADING":
      return { ...state, addingBrokerInBulkLoading: action.payload };
    case "GET_ALL_BROKERS_LOADING":
      return { ...state, getAllBrokersLoading: action.payload };
    case "GET_BROKERS_CE_TYPES_LOADING":
      return { ...state, getBrokersCETypesLoading: action.payload };
    case "ALL_BROKERS":
      return { ...state, allBrokers: action.payload };
    case "BROKERS_CE_TYPES":
      return { ...state, brokerCETypes: action.payload };
    case "SET_SINGLE_BROKER_DATA":
      return { ...state, singleBrokerData: action.payload };
    case "DELETE_BROKER_LOADING":
      return { ...state, deleteBrokerLoading: action.payload };
    case "SEND_BROKER_EMAIL_LOADING":
      return { ...state, sendBrokerEmailLoading: action.payload };
    case "SET_RESET_BROKER_REDUCER":
      return {
        ...state,
        singleBrokerData: {},
        resetCauseModal: true,
      };
    case "SET_COMBINE_BROKERS_DATA":
      return { ...state, combineBrokers: action.payload };
    //toggle feature for automatedEmails
    case "AUTOMATED_EMAIL_CHECK_LOADING":
      return { ...state, automatedEmailsFeatureLoading: action.payload };
    //FormerBrokers States
    case "BROKER_STATUS_LOADING":
      return { ...state, changeBrokerStatusLoading: action.payload };
    case "ALL_FORMER_BROKERS":
      return { ...state, formerBrokers: action.payload };
    case "GET_ALL_FORMER_BROKERS_LOADING":
      return { ...state, getAllFormerBrokersLoading: action.payload };
    //percentages
    case "SET_PERCENTAGE":
      return { ...state, percentage: action.payload };
    //change broker role
    case "CHANGE_BROKER_ROLE_LOADING":
      return { ...state, changeBrokerRoleLoading: action.payload };
    default:
      return state;
  }
};

export default brokerReducer;
