export const setOrgRoleTypesIntoFirebase =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_ROLE_TYPE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const setOrganizationRoleType = firebase
        .functions()
        .httpsCallable("setOrganizationRoleType");
      const result = await setOrganizationRoleType(data);
      if (result.data.success) {
        dispatch({
          type: "SET_ROLE_TYPE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SET_ROLE_TYPE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ROLE_TYPE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getRoleTypeFromeFirebase =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "GET_ROLE_TYPE_LOADING",
      payload: { loading: false, success: false, error: null },
    });
    try {
      const firestore = getFirestore();
      const roleTypeRef = await firestore.collection("roleTypes").get();
      let roleTypeArray = [];
      if (!roleTypeArray.empty) {
        for (const doc of roleTypeRef.docs) {
          const data = doc.data();
          const finalDoc = {
            id: doc.id,
            ...data,
          };
          roleTypeArray.push(finalDoc);
        }
      }
      dispatch({
        type: "GET_ROLE_TYPE",
        payload: roleTypeArray,
      });
      dispatch({
        type: "GET_ROLE_TYPE_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ROLE_TYPE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const deleteOrgRole =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: "DELETE_ROLE_TYPE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const deleteAdminFunction = firebase
        .functions()
        .httpsCallable("deleteAdminFunction");
      const response = await deleteAdminFunction(data);
      if (response.data.success) {
        dispatch({
          type: "DELETE_ROLE_TYPE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "DELETE_ROLE_TYPE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: response.data.errorMessage,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "DELETE_ROLE_TYPE_LOADING",
        payload: { loading: false, success: false, error: err.errorMessage },
      });
    }
  };

export const resetOrgRoleLoading = () => (dispatch) => {
  dispatch({
    type: "SET_ROLE_TYPE_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetOrgRoleDeleteLoading = () => (dispatch) => {
  dispatch({
    type: "DELETE_ROLE_TYPE_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};
