const initialState = {
  currentUser: {
    email: "",
    displayName: "",
    authLoaded: false,
    authenticated: false,
  },
  userLoginLoading: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload };
    case "USER_LOGIN_LOADING":
      return {
        ...state,
        userLoginLoading: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
