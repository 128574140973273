export const addSnapshotsListners = (snapshot) => {
  return (dispatch, getState) => {
    dispatch({
      type: "ADD_FIREBASE_SNAPSHOT_LISTENER",
      payload: snapshot,
    });
  };
};

export const getOrganiztionLabels =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "GET_ORGANIZATION_LOADING",
      payload: { loading: false, success: false, error: null },
    });

    try {
      const firestore = getFirestore();
      const orgId = getState().user.currentUser.orgId;
      const organization = await firestore
        .collection("organizations")
        .doc(orgId)
        .get();

      if (!organization.empty) {
        const organixation = organization.data();
        dispatch({
          type: "SET_ORGANIZATION",
          payload: organixation,
        });
      } else {
        dispatch({
          type: "SET_ORGANIZATION",
          payload: {},
        });
      }

      dispatch({
        type: "GET_ORGANIZATION_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ORGANIZATION_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
