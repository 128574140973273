const initialState = {
  brokerCreditStatusLoading: {
    loading: false,
    success: false,
    error: null,
  },
};

const creditStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BROKER_CREDIT_STATUS_LOADING":
      return { ...state, brokerCreditStatusLoading: action.payload };
    default:
      return state;
  }
};

export default creditStatusReducer;
