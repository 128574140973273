const initialState = {
  jurisdictionTypeLoading: {
    loading: false,
    success: false,
    error: null,
  },
  jurisdictions: [],
  jurisdictionsLoading: {
    loading: false,
    success: false,
    error: null,
  },
  deleteJurisdictionTypeLoading: {
    loading: false,
    success: false,
    error: null,
  },
  currentUserJurisdictionLoading: {
    loading: false,
    success: false,
    error: null,
  },
  currentUserJurisdiction: {},
};

const jurisdictionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_JURISDICTION_TYPE_LOADING":
      return { ...state, jurisdictionTypeLoading: action.payload };
    case "GET_JURISDICTION_TYPE":
      return { ...state, jurisdictions: action.payload };
    case "GET_JURISDICTION_TYPE_LOADING":
      return { ...state, jurisdictionsLoading: action.payload };
    case "GET_CURRENT_USER_JURISDICTION_DATA":
      return { ...state, currentUserJurisdiction: action.payload };
    case "GET_CURRENT_USER_JURISDICTION_DATA_LOADING":
      return { ...state, currentUserJurisdictionLoading: action.payload };
    case "SET_DELETE_JURISDICTION_TYPE_LOADING":
      return { ...state, deleteJurisdictionTypeLoading: action.payload };
    case "SET_JURISDICTION_CE_HOURS_LOADING":
      return { ...state, jurisdictionCEHoursLoading: action.payload };
    default:
      return state;
  }
};

export default jurisdictionReducer;
