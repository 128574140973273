import React from "react";
import { Helmet } from "react-helmet";

const HelmetComponent = ({ role }) => {
  const tabText = (role) => {
    if (role) {
      if (role === "admin") {
        return "Riskwell Admin";
      } else if (role === "manager") {
        return "Riskwell Manager";
      } else if (role === "user") {
        return "Riskwell Employee";
      } else if (role === "sys-admin") {
        return "Riskwell System Admin";
      }
    } else {
      return "Riskwell";
    }
  };

  return (
    <Helmet>
      <title>{`${tabText(role)}`}</title>
      <meta
        name="viewport"
        content={`${
          role === "admin"
            ? "width= 1100,initial-scale=1"
            : "width=device-width, initial-scale=1"
        }`}
      />
    </Helmet>
  );
};

export default HelmetComponent;
