const initialState = {
  jurisdictionCEHoursLoading: {
    loading: false,
    success: false,
    error: null,
  },
  getJurisdictionCEHoursData: {},
};

const CEHoursReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_JURISDICTION_CE_HOURS_LOADING":
      return { ...state, jurisdictionCEHoursLoading: action.payload };
    case "GET_JURISDICTION_CE_HOURS":
      return { ...state, getJurisdictionCEHoursData: action.payload };
    default:
      return state;
  }
};

export default CEHoursReducer;
