const initialState = {
  creditData: [],
  submissionCreditData: [],
  creditDataSnapshot: "",
  createCreditDataLoading: {
    loading: false,
    error: null,
    success: false,
  },

  createCreditDraftLoading: {
    loading: false,
    error: null,
    success: false,
  },
  deleteCreditLoading: {
    loading: false,
    error: null,
    success: false,
  },
  fetchCreditDataLoading: {
    loading: false,
    error: null,
    success: false,
  },
  fetchCreditApprovalLoading: {
    loading: false,
    success: false,
    error: null,
  },
  creditApprovedRejectedLoading: {
    loading: false,
    success: false,
    error: null,
  },
  creditApprovedRejectedOnBehalfBrokerLoading: {
    loading: false,
    success: false,
    error: null,
  },
  savedCreditDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  savedCreditData: [],
  creditApprovedRejectedData: [],
  creditApprovedRejectedDataOnBehalfBroker: [],
  creditApproval: [],
  creditApprovalSnapshot: null,
  creditsApprovedRejectedOnBehalfBrokerSnapshot: null,
};

const creditReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_CREDIT_DATA_LOADING":
      return { ...state, createCreditDataLoading: action.payload };
    case "SAVE_CREDIT_OF_BROKER_LOADING":
      return { ...state, saveCreditDataLoading: action.payload };
    case "CREDIT_DATA":
      return { ...state, creditData: action.payload };
    case "SUBMISSION_CREDIT_DATA":
      return { ...state, submissionCreditData: action.payload };
    case "SET_CREDIT_DATA_SNAPSHOT":
      return { ...state, creditDataSnapshot: action.payload };
    //approved and rejected credits history for the admin.
    case "GET_CREDIT_APPROVED_REJECTED_DATA_LOADING":
      return { ...state, creditApprovedRejectedLoading: action.payload };
    case "SET_CREDIT_APPROVED_REJECTED_DATA":
      return { ...state, creditApprovedRejectedData: action.payload };
    // return { ...state, creditData: [...state.creditData, ...action.payload] };
    case "CREATE_CREDIT_DRAFT_DATA_LOADING":
      return { ...state, createCreditDraftLoading: action.payload };
    case "ALL_CREDIT_DATA_LOADING":
      return { ...state, fetchCreditDataLoading: action.payload };

    //fetching saved credits of brokers
    case "CREATE_CREDIT_DATA_LOADING_TO_BANK":
      return { ...state, savedCreditDataLoading: action.payload };
    case "SET_SAVED_CREDIT_DATA_TO_BANK":
      return { ...state, savedCreditData: action.payload };
    // Credit Approval ->
    case "GET_CREDIT_APPROVAL_DATA_LOADING":
      return { ...state, fetchCreditApprovalLoading: action.payload };
    case "SET_CREDIT_APPROVAL_DATA":
      return { ...state, creditApproval: action.payload };
    case "SET_CREDIT_APPROVAL_DATA_SNAPSHOT":
      return { ...state, creditApprovalSnapshot: action.payload };
    case "SET_DELETE_CREDIT_LOADING":
      return { ...state, deleteCreditLoading: action.payload };
    case "GET_CREDIT_APPROVED_REJECTED_DATA_ON_BEHALF_BROKER_LOADING":
      return {
        ...state,
        creditApprovedRejectedOnBehalfBrokerLoading: action.payload,
      };
    case "SET_CREDIT_APPROVED_REJECTED_ON_BEHALF_BROKER_DATA":
      return {
        ...state,
        creditApprovedRejectedDataOnBehalfBroker: action.payload,
      };
    default:
      return state;
  }
};

export default creditReducer;
