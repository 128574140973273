import { logOutFunction } from "../../firebase/firebaseConfig";

export const setCurrentUser = (user) => ({
  type: "SET_CURRENT_USER",
  payload: user,
});

export const signOutAction = () => {
  return async (dispatch, getState) => {
    const { snapshots } = getState().appSettings;
    try {
      for (const snapshot of snapshots) {
        snapshot.snapshot();
      }
      await logOutFunction();
      window.location.reload();
      dispatch({ type: "RESET_REDUX_STORE" });
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const usersLoginLoading = (data) => ({
  type: "USER_LOGIN_LOADING",
  payload: data,
});

export const snapshotListeners = (data) => ({
  type: "INVITED_BROKER_SNAPSHOT",
  payload: data,
});
