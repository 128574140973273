import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

firestore.enablePersistence().catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
  console.log("Presistence error", err);
});

export const addUserNameEmailOAuth = async (userAuth) => {
  if (!userAuth) return;
  const userRef = firebase.firestore().doc(`users/${userAuth.uid}`);
  try {
    const snapShot = await userRef.get();
    if (!snapShot.exists) {
      await createUserDocumentCloudFunction(userAuth);
      return userRef;
    } else {
      return userRef;
    }
  } catch (err) {
    console.log(err.message);
    return userRef;
  }
};

export const createUserDocumentCloudFunction = async (userAuth) => {
  const functions = firebase.functions();
  const addUserNameEmailToFirebase = functions.httpsCallable(
    "addUserNameEmailToFirebase"
  );

  const userNameEmailData = JSON.stringify({
    userName: userAuth.displayName,
    userEmail: userAuth.email,
    role: "user",
  });
  try {
    const resp = await addUserNameEmailToFirebase(userNameEmailData);

    if (resp.data.success) {
    } else {
      const errorObj = {
        status: 500,
        message: resp.error,
      };
      throw errorObj;
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const logOutFunction = async () => {
  await auth.signOut();
  localStorage.clear();
};

export default firebase;
export { firebaseConfig };
