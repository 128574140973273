import { combineReducers } from "redux";
import authReducer from "./auth/auth.reducer";
import creditReducer from "./credits/credit.reducer";
import brokerReducer from "./broker/broker.reducer";
import creditStatusReducer from "./creditstatus/creditStatus.reducer";
import jurisdictionReducer from "./jurisdictionType/jurisdictionType.reducer";
import departmentReducer from "./departments/department.reducer";
import roleTypeReducer from "./roleType/roleType.reducer";
import invitedBrokerReducer from "./invitedBroker/invitedBroker.reducer";
import CEHoursReducer from "./CEHours/CEHours.reducer";
import userBrokerReducer from "./userBroker/userBroker.reducer";
import adminCreditsReducer from "./adminCredits/adminCredits.reducer";
import guidelinesReducer from "./Guidelines/guidelines.reducer";
import appSetingsReducer from "./app-settings/app-settings.reducer";
import systemAdminReducer from "./sytemAdmin/systemAdmin.reducer";
import LicensesReducer from "./licenses/licenses.reducer";
// import { firestoreReducer } from "redux-firestore";

const appReducer = combineReducers({
  user: authReducer,
  credit: creditReducer,
  broker: brokerReducer,
  licenses: LicensesReducer,
  creditStatus: creditStatusReducer,
  jurisdiction: jurisdictionReducer,
  departments: departmentReducer,
  organizationRoleTypes: roleTypeReducer,
  invitedBrokers: invitedBrokerReducer,
  CEHours: CEHoursReducer,
  userBroker: userBrokerReducer,
  adminCredits: adminCreditsReducer,
  guidelines: guidelinesReducer,
  appSettings: appSetingsReducer,
  systemAdmin: systemAdminReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "RESET_REDUX_STORE") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
