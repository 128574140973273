const initialState = {
  getAllInvitedBrokerLoading: {
    loading: false,
    success: false,
    error: null,
  },
  invitedBrokers: [],
  deleteInvitedBrokerLoading: {
    loading: false,
    success: false,
    error: null,
  },
};

const invitedBrokerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVITED_BROKERS_LOADING":
      return { ...state, getAllInvitedBrokerLoading: action.payload };
    case "SET_ALL_INVITED_BROKERS":
      return { ...state, invitedBrokers: action.payload };
    case "DELETE_INVITED_BROKER_LOADING":
      return { ...state, deleteInvitedBrokerLoading: action.payload };
    default:
      return state;
  }
};

export default invitedBrokerReducer;
