const initialState = {
  creditsData: {},
  adminCreditsLoading: { loading: false, success: false, error: null },
};

const adminCreditsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ADMIN_CREDITS_DATA":
      return { ...state, creditsData: action.payload };
    case "SET_ADMIN_CREDITS_DATA_LOADING":
      return { ...state, adminCreditsLoading: action.payload };

    default:
      return state;
  }
};

export default adminCreditsReducer;
