import {
  adminRoutes,
  brokerRoutes,
  loggedOutRoutes,
  managerRoutes,
  mergeAccountRoutes,
  systemAdminRoutes,
  undefinedRoutes,
} from "../routes";

export const getAddressToRedirect = (
  authenticated,
  role,
  resetPassword,
  mergeAccount
) => {
  if (authenticated) {
    if (role === "admin" && !mergeAccount) {
      return "/licenses-dashboard";
    } else if (role === "admin" && mergeAccount) {
      return "/merge";
    } else if (role === "user" && !mergeAccount) {
      return "/dashboard";
    } else if (role === "user" && mergeAccount) {
      return "/merge";
    } else if (role === "sys-admin") {
      return "sys-admin-dashboard";
    } else if (role === "manager" && !mergeAccount) {
      return "/manager-license-dashboard";
    } else if (role === "manager" && mergeAccount) {
      return "/merge";
    } else {
      return "/error";
    }
  } else {
    return "/login";
  }
};

// export const returnSideBar = (authenticated, role, resetPassword) => {
//   if (authenticated) {
//     if (role === "admin") {
//       return <RiskwellSidebar />;
//     } else if (role === "user") {
//       return <RiskwellSidebar />;
//     } else if (role === "manager") {
//       return <RiskwellSidebar />;
//     } else if (role === "sys-admin") {
//       return <RiskwellSidebar />;
//     }
//   }
// };

export const finalRoutes = (
  authenticated,
  role,
  resetPassword,
  mergeAccount
) => {
  let finalRoutes = [];
  if (authenticated) {
    if (role === "admin" && !mergeAccount) {
      finalRoutes = [...adminRoutes];
    } else if (role === "admin" && mergeAccount) {
      finalRoutes = [...mergeAccountRoutes];
    } else if (role === "user" && !mergeAccount) {
      finalRoutes = [...brokerRoutes];
    } else if (role === "user" && mergeAccount) {
      finalRoutes = [...mergeAccountRoutes];
    } else if (role === "sys-admin") {
      finalRoutes = [...systemAdminRoutes];
    } else if (role === "manager" && !mergeAccount) {
      finalRoutes = [...managerRoutes];
    } else if (role === "manager" && mergeAccount) {
      finalRoutes = [...mergeAccountRoutes];
    } else {
      finalRoutes = [...undefinedRoutes];
    }
  } else {
    finalRoutes = [...loggedOutRoutes];
  }
  return finalRoutes;
};
