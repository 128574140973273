import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const fetchInvitedBrokersData = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_ALL_INVITED_BROKERS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const orgId = getState().user.currentUser.orgId;
    const invitedBrokerRef = firestore
      .collection("invitedBrokers")
      .where("role", "!=", "admin")
      .where("orgId", "==", orgId)
      .where("status", "==", "active");

    try {
      let invitedBrokersSnapshot = invitedBrokerRef.onSnapshot((response) => {
        let allInvitedBrokersArray = [];
        if (response.docs.length) {
          for (const doc of response.docs) {
            const data = doc.data();
            const finalDoc = {
              id: doc.id,
              ...data,
            };
            allInvitedBrokersArray.push(finalDoc);
          }
          dispatch({
            type: "SET_ALL_INVITED_BROKERS",
            payload: allInvitedBrokersArray,
          });
          dispatch({
            type: "GET_ALL_INVITED_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_ALL_INVITED_BROKERS",
            payload: [],
          });
          dispatch({
            type: "GET_ALL_INVITED_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      });
      dispatch(
        addSnapshotsListners({
          type: "invited-brokers-snapshot",
          snapshot: invitedBrokersSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_INVITED_BROKERS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchInvitedBrokerDataForManagerRole = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "GET_ALL_INVITED_BROKERS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const { currentUser } = getState().user;
    const orgId = currentUser.orgId;
    const assignedDepartments = currentUser.departmentsAssigned;
    const invitedBrokerRef = firestore
      .collection("invitedBrokers")
      .where("orgId", "==", orgId)
      .where("status", "==", "active")
      .where("department", "in", assignedDepartments);

    try {
      let invitedBrokersSnapshot = invitedBrokerRef.onSnapshot((response) => {
        let allInvitedBrokersArray = [];
        if (response.docs.length) {
          for (const doc of response.docs) {
            const data = doc.data();
            const finalDoc = {
              id: doc.id,
              ...data,
            };
            allInvitedBrokersArray.push(finalDoc);
          }
          dispatch({
            type: "SET_ALL_INVITED_BROKERS",
            payload: allInvitedBrokersArray,
          });
          dispatch({
            type: "GET_ALL_INVITED_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_ALL_INVITED_BROKERS",
            payload: [],
          });
          dispatch({
            type: "GET_ALL_INVITED_BROKERS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      });
      dispatch(
        addSnapshotsListners({
          type: "invited-brokers-snapshot",
          snapshot: invitedBrokersSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_INVITED_BROKERS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};
export const deleteInvitedBroker =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: "DELETE_INVITED_BROKER_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const deleteAdminFunction = firebase
        .functions()
        .httpsCallable("deleteAdminFunction");
      const response = await deleteAdminFunction(data);
      if (response.data.success) {
        dispatch({
          type: "DELETE_INVITED_BROKER_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "DELETE_INVITED_BROKER_LOADING",
          payload: {
            loading: false,
            success: false,
            error: response.data.errorMessage,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "DELETE_INVITED_BROKER_LOADING",
        payload: { loading: false, success: false, error: err.errorMessage },
      });
    }
  };

export const resetDeleteInvitedBrokerLoading = () => ({
  type: "DELETE_INVITED_BROKER_LOADING",
  payload: { loading: false, success: false, error: null },
});
