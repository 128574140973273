import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const getUserBrokerData = (newData) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SET_USER_BROKER_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    const currentUser = newData ? newData : getState().user.currentUser;
    const orgId = getState().user.currentUser.orgId;
    const data = {
      province: newData ? newData.homeProvince : currentUser.homeProvince,
      license: newData ? newData.provinceLicense : currentUser.provinceLicense,
    };
    const firestore = getFirestore();
    const jurisdictionCollectionRef = firestore
      .collection("organizations")
      .doc(orgId)
      .collection("jurisdictions");
    const jurisdictionQueryRef = jurisdictionCollectionRef
      .where("provinceName", "==", data.province)
      .limit(1);
    const creditRef = await firestore
      .collection("organizations")
      .doc(orgId)
      .collection("credits")
      .where("email", "==", currentUser.email)
      .where("province", "==", currentUser.homeProvince)
      .where("license", "==", currentUser.provinceLicense)
      .get();

    let creditData = [];
    for (let i in creditRef.docs) {
      creditData.push(creditRef.docs[i].data());
    }
    try {
      const jurisdictionRes = await jurisdictionQueryRef.get();
      if (!jurisdictionRes.empty) {
        const jurisdictionId = jurisdictionRes.docs[0].id;
        const jurisdictionRef = jurisdictionCollectionRef.doc(jurisdictionId);
        const jurisdictionCEHoursRef = jurisdictionRef.collection("CEHours");
        const jurisdictionRoleRef = jurisdictionCEHoursRef.doc(data.license);

        const jurisdictionLicenseData = await jurisdictionRoleRef.get();
        if (jurisdictionLicenseData.exists) {
          const jurisdictionLicenseDataObject = jurisdictionLicenseData.data();
          const licenseData = jurisdictionLicenseDataObject;
          let newLicesnseData = licenseData;

          Object.keys(licenseData).forEach((year) => {
            const subjectsObject = licenseData[year];
            Object.keys(subjectsObject).forEach((subject) => {
              const license = currentUser.provinceLicense;
              const creditValue = creditData.find(
                (item) =>
                  item.CEType === subject &&
                  item.yearApplied === year &&
                  item.license === license &&
                  item.province === currentUser.homeProvince &&
                  item.email === currentUser.email
              );

              if (creditValue) {
                newLicesnseData[year][subject].credit = creditValue.credit
                  ? creditValue.credit
                  : 0;
                newLicesnseData[year][subject].pendingCredits =
                  creditValue.pendingCredits ? creditValue.pendingCredits : 0;

                newLicesnseData[year][subject].result = creditValue.result
                  ? creditValue.result
                  : "pending";
              }
            });
          });

          dispatch({
            type: "SET_USER_BROKER_DATA",
            payload: newLicesnseData,
          });
          dispatch({
            type: "SET_USER_BROKER_DATA_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          const errorObject = {
            status: 400,
            message:
              "No requirements set for this license yet. Please contact admin",
          };
          throw errorObject;
        }
      } else {
        const errorObject = {
          status: 400,
          message: "No jurisidction found for this user",
        };
        throw errorObject;
      }
    } catch (err) {
      dispatch({
        type: "SET_USER_BROKER_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const getUserBrokerFilterData = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    dispatchLoading({ loading: true, success: false, error: null }, dispatch);
    const orgId = getState().user.currentUser.orgId;

    const currentYear = data;
    const previousYear = (parseInt(currentYear) - 1).toString();

    try {
      let userBrokerSnapshot = await firestore
        .collection("organizations")
        .doc(orgId)
        .collection("credits")
        .where("yearApplied", "==", data)
        .onSnapshot(async (response) => {
          let creditData = response.docs.map((doc) => doc.data());
          if (!creditData.length) {
            dispatchLoadingAndData([], dispatch);
            return;
          }

          const combineBrokers = getState().broker.combineBrokers;

          if (!combineBrokers.length) {
            dispatchLoadingAndData([], dispatch);
            return;
          }

          let mergedArray = combineBrokers.flatMap((broker) => {
            return creditData
              .filter((credit) => mergeCreditAndBroker(credit, broker))
              .map((credit) => ({
                email: credit.email,
                name: credit.name,
                brokerRenewalDate: `${broker.brokerRenewalDate.day} ${broker.brokerRenewalDate.month}`,
                department: broker.department,
                license: credit.license,
                province: credit.province,
                [credit.CEType]: {
                  approved: credit.credit,
                  pendingCredits: credit.pendingCredits,
                  requirement: credit.requirement,
                  result: credit.result,
                },
                year: `${previousYear}-${data}`,
              }));
          });

          let result = Object.values(
            mergedArray.reduce((acc, { email, ...rest }) => {
              acc[email] = { ...acc[email], ...rest, email };
              return acc;
            }, {})
          );
          dispatchLoadingAndData(result, dispatch);
        });

      dispatch(
        addSnapshotsListners({
          type: "user-broker-snapshot",
          snapshot: userBrokerSnapshot,
        })
      );
    } catch (err) {
      dispatchLoading(
        { loading: false, success: false, error: err.message },
        dispatch
      );
    }
  };
};

export const getUserBrokerFilterDataByYearForManagerRole = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    dispatchLoading({ loading: true, success: false, error: null }, dispatch);
    const { currentUser } = getState().user;
    const orgId = currentUser.orgId;
    const departmentsAssigned = currentUser.departmentsAssigned;

    try {
      let userBrokerSnapshot = await firestore
        .collection("organizations")
        .doc(orgId)
        .collection("credits")
        .where("yearApplied", "==", data)
        .onSnapshot(async (response) => {
          let creditData = response.docs.map((doc) => doc.data());

          if (!creditData.length) {
            dispatchLoadingAndData([], dispatch);
            return;
          }

          const combineBrokers = [
            ...getState().broker.allBrokers,
            ...getState().invitedBrokers.invitedBrokers,
          ];

          if (!combineBrokers.length) {
            dispatchLoadingAndData([], dispatch);
            return;
          }

          let mergedArray = combineBrokers.flatMap((broker) => {
            return creditData
              .filter((credit) => mergeCreditAndBroker(credit, broker))
              .map((credit) => ({
                email: credit.email,
                name: credit.name,
                brokerRenewalDate: `${broker.brokerRenewalDate.day} ${broker.brokerRenewalDate.month}`,
                department: broker.department,
                license: credit.license,
                province: credit.province,
                [credit.CEType]: {
                  approved: credit.credit,
                  pendingCredits: credit.pendingCredits,
                  requirement: credit.requirement,
                  result: credit.result,
                },
              }));
          });
          //filter the data based on
          mergedArray = mergedArray.filter((item) =>
            departmentsAssigned.includes(item.department)
          );
          let result = Object.values(
            mergedArray.reduce((acc, { email, ...rest }) => {
              acc[email] = { ...acc[email], ...rest, email };
              return acc;
            }, {})
          );
          dispatchLoadingAndData(result, dispatch);
        });

      dispatch(
        addSnapshotsListners({
          type: "user-broker-snapshot",
          snapshot: userBrokerSnapshot,
        })
      );
    } catch (err) {
      dispatchLoading(
        { loading: false, success: false, error: err.message },
        dispatch
      );
    }
  };
};

// Helper function to check if credit and broker should be merged
const mergeCreditAndBroker = (credit, broker) => {
  return (
    credit.email === broker.email &&
    credit.province === broker.homeProvince &&
    credit.license === broker.provinceLicense
  );
};

// Dispatches both loading and data
const dispatchLoadingAndData = (data, dispatch) => {
  dispatch({
    type: "SET_CREDIT_DATA_BY_YEAR",
    payload: data,
  });
  dispatchLoading({ loading: false, success: true, error: null }, dispatch);
};

// Dispatches only the loading state
const dispatchLoading = (payload, dispatch) => {
  dispatch({
    type: "SET_BROKERS_FILTER_DATA_LOADING",
    payload: payload,
  });
};

export const getFormerBrokerFilterData = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    dispatch({
      type: "SET_FORMER_BROKERS_FILTER_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const orgId = getState().user.currentUser.orgId;
    try {
      let formerBrokerSnapshot = await firestore
        .collection("organizations")
        .doc(orgId)
        .collection("credits")
        .where("yearApplied", "==", data)
        .onSnapshot(async (response) => {
          let creditData = [];
          if (response.docs.length > 0) {
            for (let i in response.docs) {
              creditData.push(response.docs[i].data());
            }
            const formerBroker = getState().broker.formerBrokers;

            let mergedArray = [];

            if (formerBroker.length) {
              for (let i in formerBroker) {
                for (let j in creditData) {
                  if (
                    creditData[j].email === formerBroker[i].email &&
                    creditData[j].province === formerBroker[i].homeProvince &&
                    creditData[j].license === formerBroker[i].provinceLicense
                  ) {
                    mergedArray.push({
                      email: creditData[j].email,
                      name: creditData[j].name,
                      brokerRenewalDate: `${formerBroker[i].brokerRenewalDate.day} ${formerBroker[i].brokerRenewalDate.month}`,
                      department: formerBroker[i].department,
                      license: creditData[j].license,
                      province: creditData[j].province,
                      [creditData[j].CEType]: {
                        approved: creditData[j].credit,
                        pendingCredits: creditData[j].pendingCredits,
                        requirement: creditData[j].requirement,
                        result: creditData[j].result,
                      },
                    });
                  }
                }
              }

              let result = Object.values(
                mergedArray.reduce((acc, { email, ...rest }) => {
                  acc[email] = { ...acc[email], ...{ email, ...rest } };
                  return acc;
                }, {})
              );

              dispatch({
                type: "SET_FORMER_CREDIT_DATA_BY_YEAR",
                payload: result,
              });
              dispatch({
                type: "SET_FORMER_BROKERS_FILTER_DATA_LOADING",
                payload: { loading: false, success: true, error: null },
              });
            } else {
              dispatch({
                type: "SET_FORMER_CREDIT_DATA_BY_YEAR",
                payload: [],
              });
            }
          } else {
            dispatch({
              type: "SET_FORMER_BROKERS_FILTER_DATA_LOADING",
              payload: { loading: false, success: true, error: null },
            });
            dispatch({
              type: "SET_FORMER_CREDIT_DATA_BY_YEAR",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "former-broker-filter-data-snapshot",
          snapshot: formerBrokerSnapshot,
        })
      );
    } catch (err) {
      dispatch({
        type: "SET_FORMER_BROKERS_FILTER_DATA_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };
};
