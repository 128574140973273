import React, { useState } from "react";
import { Separator } from "../ui/separator";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../../redux/auth/auth.action";
import { useMemo } from "react";
import { SidebarRoleComponent } from "../sidebar-role-component/sidebar-role-component";
import { ReactComponent as RiskWellLogo } from "../../assets/risk-well-icon.svg";
import { roleBasedMenus } from "../../utils/sidebar";
import { ReactComponent as Logout } from "../../assets/logout.svg";

function RiskwellSidebar() {
  const { currentUser } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  function useRoleBasedMenuItems(role) {
    return useMemo(() => {
      return roleBasedMenus[role] || [];
    }, [role]);
  }
  const menuItems = useRoleBasedMenuItems(currentUser.role);

  const logoutClickHandler = async () => {
    dispatch(signOutAction());
  };

  return (
    <aside className="flex fixed min-h-screen w-64 flex-col overflow-y-auto border-r bg-main px-4 py-8">
      <Link to="/" className="flex space-x-4 no-underline">
        <RiskWellLogo />
        <p className="text-white text-2xl items-center flex no-underline">
          Risk Well
        </p>
      </Link>
      <SidebarRoleComponent
        currentUser={currentUser}
        open={open}
        setOpen={setOpen}
      />
      <Separator className="mt-6 bg-lesser" />
      <div className="mt-6 flex flex-1 flex-col justify-between px-2">
        <nav
          className={`-mx-3 space-y-6 ${
            open ? "transition-transform translate-y-60" : ""
          }`}
        >
          <div className="space-y-2">
            {menuItems.map((item, key) => {
              const isActive = location.pathname === item.route;
              const linkClass = isActive
                ? "bg-gray-700 text-white"
                : "text-gray-300 hover:bg-gray-500 hover:text-white";
              const boldFont = isActive ? "font-medium " : "";
              return (
                <Link
                  className={`flex transform items-center rounded-lg px-2 py-[15px] transition-colors duration-300 decoration-slice no-underline font-sans ${linkClass}`}
                  to={item.route}
                  key={key}
                >
                  <span className={`mx-2 text-sm  ${boldFont}`}>
                    {item.name}
                  </span>
                </Link>
              );
            })}
          </div>
        </nav>
        <div className="mt-6">
          <div
            className="mt-6 flex items-center justify-between cursor-pointer"
            onClick={logoutClickHandler}
          >
            <span className="text-sm  font-sans text-gray-300">Logout</span>
            <Link className="rotate-180 text-gray-300 transition-colors duration-200 no-underline hover:text-white">
              <Logout />
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default RiskwellSidebar;
