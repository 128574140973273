const initialState = {
  addOrganizationLoading: {
    loading: false,
    success: false,
    error: null,
  },
  deleteOrganizationLoading: {
    loading: false,
    success: false,
    error: null,
  },
  addJursidictionLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fetchJurisdictionsLoading: {
    loading: false,
    success: false,
    error: null,
  },
  allJurisdictions: [],
  allOrganizations: [],
  fetchOrganizationsLoading: {
    loading: false,
    success: false,
    error: null,
  },
  addCEHoursLoading: {
    loading: false,
    success: false,
    error: null,
  },

  guidelinesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  guidelinesDoc: {},
};

const systemAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ORGANIZATION_LOADING":
      return { ...state, addOrganizationLoading: action.payload };
    case "FETCH_ORGANIZATIONS_LOADING":
      return { ...state, fetchOrganizationsLoading: action.payload };
    case "SET_ORGANIZATIONS":
      return { ...state, allOrganizations: action.payload };
    // Jurisdictions
    case "ADD_JURISDICTION_LOADING":
      return { ...state, addJursidictionLoading: action.payload };
    case "SET_ALL_JURISDICTIONS":
      return { ...state, allJurisdictions: action.payload };
    case "FETCH_JURISDICTIONS_LOADING":
      return { ...state, fetchJurisdictionsLoading: action.payload };
    case "ADD_JURISDICTION_CE_LOADING":
      return { ...state, addCEHoursLoading: action.payload };
    case "SYS_ADD_GUIDELINES_LOADING":
      return { ...state, guidelinesLoading: action.payload };
    case "SET_GUIDELINES_DOC":
      return { ...state, guidelinesDoc: action.payload };
    // case "GET_GUIDELINES_DOC_LOADING":
    //   return { ...state, fetchGuidelinesLoading: action.payload };
    default:
      return state;
  }
};

export default systemAdminReducer;
