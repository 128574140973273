import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const addLicenseAction =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "ADD_LICENSE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const addLicense = firebase.functions().httpsCallable("addLicense");
      const result = await addLicense(data);
      if (result.data.success) {
        dispatch({
          type: "ADD_LICENSE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "ADD_LICENSE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ADD_LICENSE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getBrokerActiveLicenses =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const orgId = getState().user.currentUser.orgId;
      const email = getState().user.currentUser.email;

      dispatch({
        type: "GET_BROKER_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();

      let licensesSnapshot = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("email", "==", email)
        .where("active", "==", true)
        .where("status", "in", ["expired", "approved"])
        .onSnapshot((licensesSnapshot) => {
          if (!licensesSnapshot.empty) {
            let licenses = [];
            for (const doc of licensesSnapshot.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              licenses.push(finalDoc);
            }
            dispatch({
              type: "SET_BROKER_LICENSE",
              payload: licenses,
            });
            dispatch({
              type: "GET_BROKER_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "SET_BROKER_LICENSE",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "licenses-snapshot",
          snapshot: licensesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_BROKER_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const getBrokerPendingLicense =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const orgId = getState().user.currentUser.orgId;
      const email = getState().user.currentUser.email;

      dispatch({
        type: "GET_BROKER_PENDING_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let pendingLicensesSnapshot = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("email", "==", email)
        .where("active", "==", false)
        .where("status", "in", ["approved", "pending"])
        .onSnapshot((pendingLicensesSnapshot) => {
          if (!pendingLicensesSnapshot.empty) {
            let licenses = [];
            for (const doc of pendingLicensesSnapshot.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              licenses.push(finalDoc);
            }
            dispatch({
              type: "SET_BROKER_PENDING_LICENSES",
              payload: licenses,
            });
            dispatch({
              type: "GET_BROKER_PENDING_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "SET_BROKER_PENDING_LICENSES",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "licenses-pending-snapshot",
          snapshot: pendingLicensesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_BROKER_PENDING_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const getAllBrokerPendingLicenses =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const orgId = getState().user.currentUser.orgId;
      dispatch({
        type: "GET_ALL_BROKER_PENDING_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let AllPendingLicensesSnapshot = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("status", "in", ["pending", "approved"])
        .where("active", "==", false)
        .onSnapshot((AllPendingLicensesSnapshot) => {
          if (!AllPendingLicensesSnapshot.empty) {
            let AllPendinglicenses = [];
            for (const doc of AllPendingLicensesSnapshot.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              AllPendinglicenses.push(finalDoc);
            }

            dispatch({
              type: "SET_ALL_BROKERS_PENDING_LICENSES",
              payload: AllPendinglicenses,
            });
            dispatch({
              type: "GET_ALL_BROKER_PENDING_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "SET_ALL_BROKERS_PENDING_LICENSES",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "all-licenses-pending-snapshot",
          snapshot: AllPendingLicensesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_BROKER_PENDING_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const getAllBrokerPendingLicensesForManager =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const { currentUser } = getState().user;
      const { orgId, departmentsAssigned } = currentUser;

      dispatch({
        type: "GET_ALL_BROKER_PENDING_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let AllPendingLicensesSnapshot = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("status", "in", ["pending", "approved"])
        .where("department", "in", departmentsAssigned)
        .where("active", "==", false)
        .onSnapshot((AllPendingLicensesSnapshot) => {
          if (!AllPendingLicensesSnapshot.empty) {
            let AllPendinglicenses = [];
            for (const doc of AllPendingLicensesSnapshot.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              AllPendinglicenses.push(finalDoc);
            }

            dispatch({
              type: "SET_ALL_BROKERS_PENDING_LICENSES",
              payload: AllPendinglicenses,
            });
            dispatch({
              type: "GET_ALL_BROKER_PENDING_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "SET_ALL_BROKERS_PENDING_LICENSES",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "all-licenses-pending-snapshot",
          snapshot: AllPendingLicensesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_BROKER_PENDING_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const LicenseApprovalByAdmin =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "APPROVE_LICENSE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const approveBrokerLicenses = firebase
        .functions()
        .httpsCallable("approveBrokerLicenses");
      const result = await approveBrokerLicenses(data);
      if (result.data.success) {
        dispatch({
          type: "APPROVE_LICENSE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "APPROVE_LICENSE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "APPROVE_LICENSE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getAllEmployeesLicenses =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const orgId = getState().user.currentUser.orgId;

      dispatch({
        type: "GET_ALL_EMPLOYEES_APPROVED_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let allEmployeesApprovedLicenses = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("status", "in", ["approved", "expired"])
        .where("active", "==", true)
        .onSnapshot((allEmployeesApprovedLicenses) => {
          if (!allEmployeesApprovedLicenses.empty) {
            let approvedLicenses = [];
            for (const doc of allEmployeesApprovedLicenses.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              approvedLicenses.push(finalDoc);
            }

            const approvedLicenseSortedAccordingRenewalDate = approvedLicenses
              .slice()
              .sort((a, b) => {
                const renewalDateA = new Date(a.renewalDate);
                const renewalDateB = new Date(b.renewalDate);
                return renewalDateA - renewalDateB;
              });

            dispatch({
              type: "SET_ALL_EMPLOYEES_PENDING_LICENSES",
              payload: approvedLicenseSortedAccordingRenewalDate,
            });
            dispatch({
              type: "GET_ALL_EMPLOYEES_APPROVED_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "SET_ALL_EMPLOYEES_PENDING_LICENSES",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "licenses-pending-snapshot",
          snapshot: allEmployeesApprovedLicenses,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_EMPLOYEES_APPROVED_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const terminationOfLicenseByAdmin =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "TERMINATE_LICENSE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const terminateLicense = firebase
        .functions()
        .httpsCallable("terminateLicense");
      const result = await terminateLicense(data);
      if (result.data.success) {
        dispatch({
          type: "TERMINATE_LICENSE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "TERMINATE_LICENSE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "TERMINATE_LICENSE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getAllBrokerExpireLicenses =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const orgId = getState().user.currentUser.orgId;
      dispatch({
        type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let AllExpiredLicensesSnapshot = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("status", "==", "expired")
        .where("active", "==", false)
        .onSnapshot((AllExpiredLicensesSnapshot) => {
          if (!AllExpiredLicensesSnapshot.empty) {
            let AllExpiredlicenses = [];
            for (const doc of AllExpiredLicensesSnapshot.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              AllExpiredlicenses.push(finalDoc);
            }

            dispatch({
              type: "SET_ALL_BROKERS_EXPIRED_LICENSES",
              payload: AllExpiredlicenses,
            });
            dispatch({
              type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "all-licenses-expired-snapshot",
          snapshot: AllExpiredLicensesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const getAllBrokerExpireLicensesForManager =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const { currentUser } = getState().user;
      const orgId = currentUser.orgId;
      const assignedDepartments = currentUser.departmentsAssigned;
      dispatch({
        type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let AllExpiredLicensesSnapshot = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("status", "==", "expired")
        .where("active", "==", false)
        .where("department", "in", assignedDepartments)
        .onSnapshot((AllExpiredLicensesSnapshot) => {
          if (!AllExpiredLicensesSnapshot.empty) {
            let AllExpiredlicenses = [];
            for (const doc of AllExpiredLicensesSnapshot.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              AllExpiredlicenses.push(finalDoc);
            }

            dispatch({
              type: "SET_ALL_BROKERS_EXPIRED_LICENSES",
              payload: AllExpiredlicenses,
            });
            dispatch({
              type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "all-licenses-expired-snapshot",
          snapshot: AllExpiredLicensesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_BROKER_EXPIRED_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const deleteBrokerPendingLicense =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "DELETE_LICENSE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const deleteLicense = firebase
        .functions()
        .httpsCallable("deleteAdminFunction");
      const result = await deleteLicense(data);
      if (result.data.success) {
        dispatch({
          type: "DELETE_LICENSE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "DELETE_LICENSE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "DELETE_LICENSE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const getAllEmployeesLicensesForManager =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      const { currentUser } = getState().user;
      const { orgId } = currentUser;
      const departmentsAssigned =
        currentUser.role === "manager" ? currentUser.departmentsAssigned : "";
      dispatch({
        type: "GET_ALL_EMPLOYEES_APPROVED_LICENSE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let allEmployeesApprovedLicenses = firestore
        .collection("organizations")
        .doc(orgId)
        .collection("licenses")
        .where("status", "in", ["approved", "expired"])
        .where("department", "in", departmentsAssigned)
        .where("active", "==", true)
        .onSnapshot((allEmployeesApprovedLicenses) => {
          if (!allEmployeesApprovedLicenses.empty) {
            let approvedLicenses = [];
            for (const doc of allEmployeesApprovedLicenses.docs) {
              const data = doc.data();
              const finalDoc = {
                ...data,
                id: doc.id,
              };
              approvedLicenses.push(finalDoc);
            }

            const approvedLicenseSortedAccordingRenewalDate = approvedLicenses
              .slice()
              .sort((a, b) => {
                const renewalDateA = new Date(a.renewalDate);
                const renewalDateB = new Date(b.renewalDate);
                return renewalDateA - renewalDateB;
              });

            dispatch({
              type: "SET_ALL_EMPLOYEES_PENDING_LICENSES",
              payload: approvedLicenseSortedAccordingRenewalDate,
            });
            dispatch({
              type: "GET_ALL_EMPLOYEES_APPROVED_LICENSE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "SET_ALL_EMPLOYEES_PENDING_LICENSES",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "licenses-pending-snapshot",
          snapshot: allEmployeesApprovedLicenses,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_ALL_EMPLOYEES_APPROVED_LICENSE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

//reset approve Broker License Loading
export const resetApproveLicenseLoading = () => ({
  type: "APPROVE_LICENSE_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetAddLicenseLoading = () => ({
  type: "ADD_LICENSE_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetDeleteLicenseLoading = () => ({
  type: "DELETE_LICENSE_LOADING",
  payload: { loading: false, success: false, error: null },
});
