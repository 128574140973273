import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import React from "react";
import { changeBrokerRole } from "../../redux/broker/broker.action";
import { useDispatch } from "react-redux";
import { SidebarAvatar } from "../sidebar-avatar/sidebar-avatar";

export function SidebarRoleComponent({ currentUser, open, setOpen }) {
  const role = currentUser.role;
  const dispatch = useDispatch();

  let options = []; // Default roles
  if (currentUser.canBeManager && currentUser.role !== "manager") {
    options.push("Manager");
  }
  if (currentUser.canBeAdmin && currentUser.role !== "admin") {
    options.push("Admin");
  }
  if (role === "admin" && currentUser.status === "active") {
    options.push("Broker");
  }
  if (role === "manager" && currentUser.status === "active") {
    options.push("Broker");
  }

  const handleRoleChange = (newRole) => {
    if (newRole === "Admin") {
      dispatch(changeBrokerRole({ type: "admin", email: currentUser.email }));
    } else if (newRole === "Manager") {
      dispatch(changeBrokerRole({ type: "manager", email: currentUser.email }));
    } else {
      dispatch(changeBrokerRole({ type: "user", email: currentUser.email }));
    }
  };

  return (
    <div className="flex justify-between items-center bg-lesser rounded-md p-1">
      <div>
        <Select
          defaultValue={open}
          onValueChange={(newRole) => {
            handleRoleChange(newRole);
          }}
          className="cursor-pointer"
          disabled={!options.length > 0}
          onClick={() => setOpen(!open)}
        >
          <SelectTrigger className="w-[200px] font-sans h-12  bg-lesser text-gray-300">
            <SelectValue className="flex justify-between flex-row">
              <span className="flex items-center justify-between capitalize">
                <SidebarAvatar />
                <span className="text-xs text-justify ml-4 flex flex-col">
                  <span className="font-bold ">
                    {role === "user" ? "Broker" : role}
                  </span>
                  <span>{currentUser.displayName} </span>
                </span>
              </span>
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="bg-white">
            {options.map((item) => {
              return (
                <SelectItem key={item} value={item}>
                  {item}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
