import { auth, firestore } from "../firebase/firebaseConfig";

export const userAuthMerged = async () => {
  const userAuth = auth.currentUser;
  const redirectResult = await auth.getRedirectResult();
  let isZensuranceEmployee = false;
  let mergeAccount = false;
  if (
    redirectResult.user &&
    redirectResult?.additionalUserInfo?.profile?.iss ===
      "https://oauth.id.jumpcloud.com/"
  ) {
    const userDisplayName =
      redirectResult.additionalUserInfo.profile.displayName;
    const userEmail = redirectResult.additionalUserInfo.profile.email;
    if (userDisplayName) {
      await userAuth.updateProfile({ displayName: userDisplayName });
    } else {
      const displayName = extractUsername(userEmail);
      await userAuth.updateProfile({ displayName });
    }

    await userAuth.updateEmail(userEmail);
  } else {
    isZensuranceEmployee =
      userAuth.email.endsWith("@zensurance.com") ||
      userAuth.email.endsWith("@endtest-mail.io");

    if (isZensuranceEmployee) {
      mergeAccount = true;
    }
  }
  return { mergeAccount, isZensuranceEmployee };
};

export const createUserDataForRedux = async ({
  userAuth,
  snapShot,
  mergeAccount,
  isZensuranceEmployee,
}) => {
  const userData = {
    uid: userAuth.uid,
    verified: userAuth.emailVerified,
    authLoaded: true,
    authenticated: true,
    email: userAuth.email,
    mergeAccount: mergeAccount && isZensuranceEmployee,
  };

  const finalUserData = Object.assign(userData, snapShot?.data());

  !finalUserData.mergeAccount &&
    (await firestore
      .collection("users")
      .doc(userAuth.uid)
      .set({ mergeAccount: false }, { merge: true }));

  return finalUserData;
};

const extractUsername = (email) => {
  const atIndex = email.indexOf("@");
  if (atIndex !== -1) {
    return email.substring(0, atIndex);
  } else {
    return email;
  }
};
