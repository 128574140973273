const initialState = {
  addRoleTypeLoading: {
    loading: false,
    success: false,
    error: null,
  },
  roleTypes: [],
  getRoleTypeLoading: {
    loading: false,
    success: false,
    error: null,
  },
  deleteRoleTypeLoading: {
    loading: false,
    success: false,
    error: null,
  },
};

const roleTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ROLE_TYPE_LOADING":
      return { ...state, addRoleTypeLoading: action.payload };
    case "GET_ROLE_TYPE":
      return { ...state, roleTypes: action.payload };
    case "GET_ROLE_TYPE_LOADING":
      return { ...state, getRoleTypeLoading: action.payload };
    case "DELETE_ROLE_TYPE_LOADING":
      return { ...state, deleteRoleTypeLoading: action.payload };
    default:
      return state;
  }
};

export default roleTypeReducer;
