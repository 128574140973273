const initialState = {
  guidelinesDoc: {},
  addGuidelinesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  deleteGuidelinesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fetchGuidelinesLoading: {
    loading: false,
    success: false,
    error: null,
  },
};

const guidelinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_GUIDELINES_LOADING":
      return {...state, addGuidelinesLoading: action.payload};
    case "SET_GUIDELINES_DOC":
      return {...state, guidelinesDoc: action.payload};
    case "DELETE_GUIDELINES_LOADING":
      return {...state, deleteGuidelinesLoading: action.payload};
    case "GET_GUIDELINES_DOC_LOADING":
      return {...state, fetchGuidelinesLoading: action.payload};
    default:
      return state;
  }
};

export default guidelinesReducer;
