import { addSnapshotsListners } from "../app-settings/app-settings.action";
//create functions for sys admin side
export const AddOrganizations =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "ADD_ORGANIZATION_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const addOrganizations = firebase
        .functions()
        .httpsCallable("addOrganization");
      const result = await addOrganizations(data);
      if (result.data.success) {
        dispatch({
          type: "ADD_ORGANIZATION_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "ADD_ORGANIZATION_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ADD_ORGANIZATION_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const AddJurisdictions =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "ADD_JURISDICTION_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const addJurisdiction = firebase
        .functions()
        .httpsCallable("addJurisdiction");
      const result = await addJurisdiction(data);
      if (result.data.success) {
        dispatch({
          type: "ADD_JURISDICTION_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "ADD_JURISDICTION_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ADD_JURISDICTION_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const AddJurisdictionsCEHours =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "ADD_JURISDICTION_CE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const addJurisdictionCEHours = firebase
        .functions()
        .httpsCallable("addJurisdictionCEHours");
      const result = await addJurisdictionCEHours(data);
      if (result.data.success) {
        dispatch({
          type: "ADD_JURISDICTION_CE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "ADD_JURISDICTION_CE_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ADD_JURISDICTION_CE_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

//Fectch Functions of sys admin side
export const fetchAllJurisdictions = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "FETCH_JURISDICTIONS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const jurisdictionRef = firestore.collection("jurisdictions");

    try {
      let jurisdictionSnapshot = jurisdictionRef.onSnapshot(
        async (response) => {
          let allJurisdictionsArray = [];
          if (response.docs.length) {
            for (const doc of response.docs) {
              const data = doc.data();

              const ceHoursSnapshot = await doc.ref.collection("CEHours").get();
              const ceHoursObject = {};

              if (!ceHoursSnapshot.empty) {
                ceHoursSnapshot.docs.forEach((ceDoc) => {
                  const ceData = ceDoc.data();
                  ceHoursObject[ceDoc.id] = ceData;
                });
              }

              const finalDoc = {
                id: doc.id,
                ...data,
                CEHours: ceHoursObject,
              };

              allJurisdictionsArray.push(finalDoc);
            }

            dispatch({
              type: "SET_ALL_JURISDICTIONS",
              payload: allJurisdictionsArray,
            });

            dispatch({
              type: "FETCH_JURISDICTIONS_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          } else {
            dispatch({
              type: "SET_ALL_JURISDICTIONS",
              payload: [],
            });

            dispatch({
              type: "FETCH_JURISDICTIONS_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          }
        }
      );

      dispatch(
        addSnapshotsListners({
          type: "ALL_JURISDICTIONS_SNAPSHOT",
          snapshot: jurisdictionSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_JURISDICTIONS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchAllOrganizations = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "FETCH_ORGANIZATIONS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const organizationRef = firestore.collection("organizations");

    try {
      let organizationSnapshot = organizationRef.onSnapshot((response) => {
        let allOrganizationsArray = [];

        if (response.docs.length) {
          for (const doc of response.docs) {
            const data = doc.data();

            const finalDoc = {
              id: doc.id,
              ...data,
            };
            allOrganizationsArray.push(finalDoc);
          }
          dispatch({
            type: "SET_ORGANIZATIONS",
            payload: allOrganizationsArray,
          });
          dispatch({
            type: "FETCH_ORGANIZATIONS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_ORGANIZATIONS",
            payload: [],
          });
          dispatch({
            type: "FETCH_ORGANIZATIONS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      });
      dispatch(
        addSnapshotsListners({
          type: "ALL_ORAGNIZATIONS_SNAPSHOT",
          snapshot: organizationSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ORGANIZATIONS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const sysAddGuidelines =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SYS_ADD_GUIDELINES_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firebase = getFirebase();
      const setGuidelines = firebase
        .functions()
        .httpsCallable("sysSetGuidelines");
      const result = await setGuidelines(data);
      if (result.data.success) {
        dispatch({
          type: "SYS_ADD_GUIDELINES_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SYS_ADD_GUIDELINES_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SYS_ADD_GUIDELINES_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };
export const resetOrganizationLoading = () => (dispatch) => {
  dispatch({
    type: "ADD_ORGANIZATION_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetJurisdictionLoading = () => (dispatch) => {
  dispatch({
    type: "ADD_JURISDICTION_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetAddCEHoursLoading = () => (dispatch) => {
  dispatch({
    type: "ADD_JURISDICTION_CE_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

export const resetAddGuidelinesLoading = () => (dispatch) => {
  dispatch({
    type: "SYS_ADD_GUIDELINES_LOADING",
    payload: { loading: false, success: false, error: null },
  });
};

