import { User2 } from "lucide-react";
import React from "react";

export function SidebarAvatar() {
  return (
    <div className="w-8 h-8 rounded-full  bg-white flex justify-center items-center">
      <User2 className="text-gray-900" size={22} strokeWidth={1.75} />
    </div>
  );
}
