const initialState = {
  snapshots: [],
  reloadApplicationLoading: false,
  getOrganizationLoading: {
    loading: false,
    success: true,
    error: null,
  },
  organization: {},
};

const appSetingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_FIREBASE_SNAPSHOT_LISTENER":
      return { ...state, snapshots: [...state.snapshots, action.payload] };
    case "REMOVE_SNAPSHOT_BY_TYPE":
      let newSnapshots = state.snapshots.filter(
        (item) => item.type !== action.payload
      );
      return { ...state, snapshots: newSnapshots };
    case "RELOAD_APPLICATION_LOADING":
      return { ...state, reloadApplicationLoading: action.payload };
    case "GET_ORGANIZATION_LOADING":
      return { ...state, getOrganizationLoading: action.payload };
    case "SET_ORGANIZATION":
      return { ...state, organization: action.payload };
    default:
      return state;
  }
};

export default appSetingsReducer;
