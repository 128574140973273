const initialState = {
  brokerData: {},
  brokerDataLoading: { loading: false, success: false, error: null },
  creditDataByYear: [],
  formerCreditDataByYear: [],
  formerBrokerFilterDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  brokerFilterDataLoading: { loading: false, success: false, error: null },
};

const userBrokerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_BROKER_DATA":
      return { ...state, brokerData: action.payload };
    case "SET_USER_BROKER_DATA_LOADING":
      return { ...state, brokerDataLoading: action.payload };
    // active broker result
    case "SET_CREDIT_DATA_BY_YEAR":
      return { ...state, creditDataByYear: action.payload };
    case "SET_BROKERS_FILTER_DATA_LOADING":
      return { ...state, brokerFilterDataLoading: action.payload };
    // former Broker result
    case "SET_FORMER_CREDIT_DATA_BY_YEAR":
      return { ...state, formerCreditDataByYear: action.payload };
    case "SET_FORMER_BROKERS_FILTER_DATA_LOADING":
      return { ...state, formerBrokerFilterDataLoading: action.payload };
    default:
      return state;
  }
};

export default userBrokerReducer;
