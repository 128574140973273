const initialState = {
  departmentsData: [],
  addDepartmentLoading: {
    loading: false,
    success: false,
    error: null,
  },
  deleteDepartmentLoading: {
    loading: false,
    success: false,
    error: null,
  },
  sendDepartmentEmailLoading: {
    loading: false,
    success: false,
    error: null,
  },
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_DEPARTMENT_LOADING":
      return { ...state, addDepartmentLoading: action.payload };
    case "SET_DEPARTMENTS_DOCS":
      return { ...state, departmentsData: action.payload };
    case "DELETE_DEPARTMENT_LOADING":
      return { ...state, deleteDepartmentLoading: action.payload };
    case "SEND_DEPARTMENT_EMAIL_LOADING":
      return { ...state, sendDepartmentEmailLoading: action.payload };
    default:
      return state;
  }
};

export default departmentReducer;
